<template>
  <div class="content-right__lists">
    <div class="list-item">
      <div class="list-item__label _font-size__sub__heading _font-weight__600">Rusting</div>
      <!-- 
          width: calc(var(--main-width) - 490px - 40px - 50px)
          这里需要使用计算，不能使用 100% ，因为 100% 会有个计算过程需要等待父级计算结束，在进行计算
          100vw 则在页面渲染立即计算不需要等待父级计算。
          490px 为左侧元素宽度 50px 为左右元素之间间距，40px 右侧元素左右内边距和
          -->
      <PowerFulTable
        ref="table"
        v-loading="loading"
        :data="listResult.rows"
        :columns="columns"
        :options="options"
        :operates="operates"
        :surplusHeight="48"
        @sortCustom="handleSortCustom"
        @rowClick="$emit('current', $event)"
      />

      <el-pagination
        v-if="Number(listResult.total) !== 0"
        background
        layout="->, prev, pager, next"
        :total="Number(listResult.total)"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import PowerFulTable from '@/components/table/powerful-table'
import { alertFindPage } from '@/api/home'
import { headers } from '../index-data'

export default {
  components: {
    PowerFulTable
  },
  props: {
    // 肯定不为 undefined 和 {}
    formData: Object
  },
  data() {
    return {
      options: {
        property: { highlightCurrentRow: true }
      },
      listResult: {
        rows: [],
        total: 0
      },
      columns: headers,
      loading: true,
      operates: {
        list: [
          {
            type: 'info',
            show: true,
            label: 'View Detail',
            click: (row) => {
              this.$emit('view-detail', {
                ...row
              })
            }
          }
        ]
      },

      listQuery: {
        orderBy: '',
        sort: '',
        type: 'Rusting Issue',
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  activated() {
    this.getData()
  },
  methods: {
    getData(data = {}) {
      this.loading = true

      this.$refs.table.$refs.multipleTable.setCurrentRow()
      alertFindPage({ ...this.formData, ...this.listQuery, ...data }).then((res) => {
        this.loading = false
        this.listResult = res.result
      })
    },
    handleSortCustom({ prop, order }) {
      this.listQuery.orderBy = prop
      this.listQuery.sort = order ? order.replace('ending', '') : ''
      this.getData()
    },
    handleCurrentChange(e) {
      this.listQuery.pageNo = e
      this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
.content-right__lists ::v-deep .cell button {
  transition: all 0.3s;
  opacity: 0;
}
.content-right__lists ::v-deep .el-table__row:hover .cell button {
  opacity: 1;
}
</style>
